import React from "react";

/**
 * size = large/small
 * align = center/left
 */
export function BlackBoxText({ align = "left", size = "large", children }) {
  return (
    <div className={`blackBoxTextWrapper blackBoxTextWrapper--${align} `}>
      <div className={`blackBoxText`}>
        {size === "large" ? <h2>{children}</h2> : null}
        {size === "small" ? <h3>{children}</h3> : null}
      </div>
    </div>
  );
}
