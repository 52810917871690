import React from "react";

/**
 * size = large/small
 * align = center/left
 */
export function TextBlock({ size = "large", children, align = "center" }) {
  return (
    <div className={`textBlock textBlock--${size} textBlock--${align}`}>
      {children}
    </div>
  );
}
