import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import { Page } from "components/Page";
import { Content } from "components/Content";
import { BlackBoxText } from "components/BlackBoxText";
import { TextBlock } from "components/TextBlock";
import { List } from "components/List";

const NotFoundPage = (p) => {
  return (
    <Page light={false} fixed={false} locationSearch={p.location.search}>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={"404 | Exeter Students' Guild Events"}
        meta={[
          {
            name: `description`,
            content: "Page not found",
          },
          {
            property: `og:url`,
            content: `https://events.exeterguild.com${p.location.pathname}`,
          },
          {
            property: `og:title`,
            content: "404 | Exeter Students' Guild Events",
          },
          {
            property: `og:description`,
            content: ``,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Content>
        <List size="large">
          <BlackBoxText align="center">404</BlackBoxText>
          <List size="medium">
            <TextBlock>
              <p>Oops! This page doesn't exist.</p>
              <p>
                <Link to="/">Back to home</Link>
              </p>
            </TextBlock>
          </List>
        </List>
      </Content>
    </Page>
  );
};

export default NotFoundPage;
